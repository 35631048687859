import React, { useState } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import "./App.css";
import instagramm from "./assets/instagramm.svg";
import twitter from "./assets/twitter.svg";

import Landingpage from "./LandingPage";

function App() {
  // console.log(location.pathname);
  return (
    <BrowserRouter>
      <div id="App">
        <Switch>
          <Route exact path={`/`}>
            <Landingpage></Landingpage>
          </Route>
          {/* <Route exact path={`/how_to_stake_de`}>
            <HowToStakeDE></HowToStakeDE>
          </Route> */}

          <Route>
            <Landingpage></Landingpage>
          </Route>
        </Switch>

        <div className="social">
          <span className="socialLink">
            <a
              href="https://www.instagram.com/stakeforfuture"
              target="_blank"
              className="cardanoLink socialLink"
            >
              <img src={instagramm} alt="instagramm" />{" "}
              instagram.com/stakeforfuture
            </a>
          </span>
          <span className="socialLink">
            <a
              href="https://www.twitter.com/stake_for"
              target="_blank"
              className="cardanoLink socialLink"
            >
              <img src={twitter} alt="instagramm" />
              twitter.com/stake_for
            </a>
          </span>
        </div>
        <div className="disclaimer">Copyright © 2021 Stake for Future</div>
      </div>
    </BrowserRouter>
  );
}

export default App;
