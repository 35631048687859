import React, { useState, useEffect } from "react";
import "./App.css";
// import instagramm from "./assets/instagramm.svg";
// import twitter from "./assets/twitter.svg";
import { CopyToClipboard } from "react-copy-to-clipboard";
// import CoopsBox from "./CoopsBox";

type TPoolData = {
  pool_id: string;
  hex: string;
  vrf_key: string;
  blocks_minted: number;
  live_stake: string;
  live_size: number;
  live_saturation: number;
  live_delegators: number;
  active_stake: string;
  active_size: string;
  declared_pledge: string;
  live_pledge: string;
  margin_cost: number;
  fixed_cost: string;
  reward_account: string;
  owners: string[];
  registration: string[];
  retirement: string[];
};

type TPoolHistoryItem = {
  active_size: number;
  active_stake: string;
  blocks: number;
  delegators_count: number;
  epoch: number;
  fees: string;
  rewards: string;
};

type TPoolHistory = TPoolHistoryItem[];

type ROIS = number[];

const lovelance = 1000000;

function LandingPage() {
  const [showDetails, setShowDetails] = useState(false);
  const [copied, setSetCopied] = useState(false);
  const [poolData, setPoolData] = useState<TPoolData | null>(null);
  const [poolHistory, setPoolHistory] = useState<TPoolHistory>([]);
  const [roa30d, setRoa30d] = useState<number>(0);
  const poolID_Azul =
    "pool1r8zkh6zns7rtpvwa466qf8a0dgqd7r9zmu27qkcyw25xv46y97s";
  const ROAEpochs = 10;
  useEffect(() => {
    if (process.env.REACT_APP_BLOCKFROST !== undefined) {
      // ****************

      window
        .fetch(
          `https://cardano-mainnet.blockfrost.io/api/v0/pools/${poolID_Azul}`,
          {
            method: "GET",
            headers: {
              project_id: process.env.REACT_APP_BLOCKFROST,
            },
          }
        )
        .then((res) => res.json())
        .then((data) => {
          setPoolData(data);
          console.log(data);
        })
        .catch((err) => console.error(err));

      // ****************

      window
        .fetch(
          `https://cardano-mainnet.blockfrost.io/api/v0/pools/${poolID_Azul}/history?count=${ROAEpochs}&page=${1}&order=desc`,
          {
            method: "GET",
            headers: {
              project_id: process.env.REACT_APP_BLOCKFROST,
            },
          }
        )
        .then((res) => res.json())
        .then((data) => {
          setPoolHistory(data);
          // setPoolData(data);
        })
        .catch((err) => console.error(err));
    }
  }, []);

  useEffect(() => {
    let totalROI = 0;

    console.log("poolHistory", poolHistory.length);
    console.log(poolHistory);

    let activeEpochs = 0;

    if (poolData?.live_pledge) {
      poolHistory.forEach((element: TPoolHistoryItem) => {
        const rewards = parseInt(element.rewards) / lovelance;
        const fees = parseInt(element.fees) / lovelance;
        const activeStake = parseInt(element.active_stake) / lovelance;
        const activePledge = parseFloat(poolData.live_pledge) / lovelance;
        const allIncome = fees + rewards;
        const margin_cost = poolData.margin_cost;
        activeEpochs += 1;

        if (allIncome > 0) {
          let epochsPerYear = 365 / 5;
          let allIncome = rewards + fees;
          let fixedFee = 340;

          if (allIncome < fixedFee) {
            fixedFee = allIncome;
          }

          allIncome = allIncome - fixedFee;
          const distributedrewards = allIncome - allIncome * margin_cost;

          const ROI =
            // (distributedrewards / (activeStake - activePledge)) *
            (distributedrewards / activeStake) * epochsPerYear * 100;
          console.log("ROI", ROI);

          totalROI += ROI;
        }
      });

      let roa30d = totalROI / ROAEpochs;
      console.log("totalROI", roa30d);

      setRoa30d(roa30d);
    }
  }, [poolHistory, poolData]);

  return (
    <div className="siteWrapper">
      <div className="contentWrapper">
        {/* <div className="navbar">
          <a href="./knowhow">Sharing Knowledge</a>
        </div> */}
        <Logo size="Large"></Logo>

        <h1>
          Stake For<br></br>future
        </h1>
        <div className="infoSmall">
          A{" "}
          <a
            className="cardanoLink"
            href="https://cardano.org/"
            target="_blank"
          >
            Cardano
          </a>{" "}
          Pool
        </div>
        <div className="subline">
          <div className=""> 1.5% Sustainable Fee </div>{" "}
          <div className="">
            <span className="nobreak">High Five to&nbsp;</span>
            <span className="nobreak">Mother Earth</span>
          </div>
        </div>
        <div className="claim">
          Due to our stable grown network of sustainable companies, institutions
          and NGOs,
          <span className="sff"> STAKE FOR FUTURE</span> can guarantee that the
          „social-sustainable stake“ will be donated to the right projects. All
          our delegators can be part of the decision which project to support.
          <div className="subline performance">
            {poolData ? (
              <>
                <div className="poolID">
                  <div className="perf_value">AZUL</div>
                  <div className="perf_name">Pool Ticker</div>
                </div>

                <div className="perf_value">{poolData.blocks_minted}</div>
                <div className="perf_name">Lifetime Blocks</div>

                <div className="perf_value">
                  {numberWithSpace(toFullInt(poolData.declared_pledge))} ₳
                </div>
                <div className="perf_name">Pledge</div>
                <div className="perf_value">
                  {numberWithSpace(toFullInt(poolData.live_stake))} ₳
                </div>
                <div className="perf_name">Stake</div>
                {/* <div className="perf_value">
                  {(Math.round(roa30d * 100) / 100).toFixed(2)}%
                </div> */}
                {/* 
                <div className="perf_name">
                  ROA p.a. during last {ROAEpochs} Epochs.
                </div> */}
              </>
            ) : (
              <>
                <div>
                  <br></br>
                </div>
                <div>
                  <br></br>
                </div>
                <div>
                  <br></br>
                </div>
                <div>
                  <br></br>
                </div>
              </>
            )}
          </div>
        </div>
        <div className="subClaimWrapper">
          <div className="subClaim aa">Professional managed Cardano Pool.</div>
          <div className="subClaim bb">
            A new approach in environmental protection
          </div>
          <div className="subClaim cc">
            Sharing is caring. <br></br>In a profit-orientated way
          </div>
        </div>

        {/* <CoopsBox roa30d={roa30d} ROAEpochs={ROAEpochs}></CoopsBox> */}

        <div className="poolBox">
          <div className="h2Box">
            <h2 onClick={() => setShowDetails(!showDetails)}>Our Pool: AZUL</h2>
          </div>

          <div className="poolDetails white">
            <div className="poolID">
              <span className="epsilon">PoolID: {poolID_Azul}</span>
              <CopyToClipboard
                text={poolID_Azul}
                onCopy={() => {
                  setSetCopied(true);
                  setTimeout(() => setSetCopied(false), 2000);
                }}
              >
                <button className="btnInfo">
                  {copied ? "copied" : "copy Pool ID"}
                </button>
              </CopyToClipboard>
            </div>
            {/* {poolData ? (
              <PoolData poolData={poolData} roa30d={roa30d}></PoolData>
            ) : null} */}
            <h2>Find us on</h2>
            <div className="poolLinks">
              <div>
                <a
                  href="https://pool.pm/19c56be8538786b0b1ddaeb4049faf6a00df0ca2df15e05b0472a866"
                  className="cardanoLink pool"
                  target="_blank"
                >
                  pool.pm
                </a>{" "}
                <a
                  href="https://pooltool.io/pool/19c56be8538786b0b1ddaeb4049faf6a00df0ca2df15e05b0472a866/"
                  className="cardanoLink pool"
                  target="_blank"
                >
                  pooltool.io
                </a>{" "}
                <a
                  href="https://cexplorer.io/pool/pool1r8zkh6zns7rtpvwa466qf8a0dgqd7r9zmu27qkcyw25xv46y97s"
                  className="cardanoLink pool"
                  target="_blank"
                >
                  cexplorer.io
                </a>
              </div>
            </div>
          </div>
          <div className="centerBox">
            <button
              className="btnInfo"
              onClick={() => setShowDetails(!showDetails)}
            >
              {showDetails ? "Hide Details" : "Show Details"}
            </button>
          </div>
          <div className={`monitoring ${showDetails ? " show" : " hide"}`}>
            <div className="serverDetails">
              <div>
                <li>Running on 100% renewable energy</li>
                <li>NVMe SSD vServer</li>
                <li>2x Xeon o. Epyc CPU 2,0 - 3,0 Ghz (dedicated)</li>
                <li>10GB DDR4 ECC Ram</li>
              </div>
              <div>
                <li>40GB NVMe SSD Festplatte</li>
                <li>200MBit Network connection</li>
                <li>DDOS Protected IP Adresses</li>
                <li>24/7 Server Monitoring</li>
              </div>
            </div>
            <iframe
              className="poolIframe"
              width="500"
              height="400"
              frameBorder="0"
              src="https://js.adapools.org/widget.html?pool=19c56be8538786b0b1ddaeb4049faf6a00df0ca2df15e05b0472a866"
            >
              <a href="https://adapools.org/pool/19c56be8538786b0b1ddaeb4049faf6a00df0ca2df15e05b0472a866">
                Detail
              </a>
            </iframe>
          </div>
        </div>

        <div className="h2Box">
          <h2>
            <span className="nobreak">How to stake&nbsp;</span>
            <span className="nobreak"> with us ?</span>
          </h2>
        </div>

        <br></br>
        <div>
          <a
            className="cardanoLink white"
            href={`${process.env.PUBLIC_URL}/HowToStake_StakeForFuture_EN_web.pdf`}
            target="_blank"
          >
            ➜ Learn&#160;How&#160;to&#160;stake&#160;with&#160;us.
          </a>{" "}
        </div>
        <br></br>
        <div>
          <a
            className="cardanoLink white"
            href={`${process.env.PUBLIC_URL}/HowToStake_StakeForFuture_DE_web.pdf`}
            target="_blank"
          >
            ➜ Lerne&#160;bei&#160;uns&#160;zu&#160;staken.
          </a>
        </div>
        <div className="bulletWord">
          <div className="text">How to puchase ADA&#160;?</div>
        </div>
        <div className="statement">
          We recommend a Crypto exchange as Kraken or Binance.
        </div>

        <div className="h2Box">
          <h2>
            <span className="nobreak">Why staking&nbsp;</span>
            <span className="nobreak"> with us ?</span>
          </h2>
        </div>

        <div className="whyWithUs copy white">
          Our goal is to be the most reliable, sustainable and efficient Cardano
          Stake Pool in the Cardano universe.
          <div className="bulletWord">
            <div className="text">Fair</div>
          </div>
          <div className="statement">
            1.5% of our pool profit, is donated quarterly to a social or
            sustainable project.The Project will be selected by our delegators
            and announced on Instagram and Twitter. Remember: There is no
            plan(et)&nbsp;B. Cheers to Mother Earth.
          </div>
          <div className="bulletWord">
            <div className="text">Reliable</div>
          </div>
          <div className="statement">
            Our servers are running 24/7 with high reliability. We have one
            producer node, and a second producer node as failover in a different
            location. We run two public relays nodes an 3 hidden relays in
            different server centers to maintain high availability at all times.
          </div>
          <div className="bulletWord">
            <div className="text">Sustainable</div>
          </div>
          <div className="statement">
            Our servers are running 100% on renewable energy. Because we try to
            be sustainable in every way.
          </div>
          <div className="bulletWord">
            <div className="text">Independent</div>
          </div>
          <div className="statement">
            We are not affiliated or sponsored by any of the big exchanges or
            any other big company. And we like to stay independent together with
            our delegators.
          </div>
        </div>

        <div className="h2Box">
          <h2>
            <span className="nobreak">Why Runing a&nbsp;</span>
            <span className="nobreak">Cardano Pool?</span>
          </h2>
        </div>
        <div className="copy">
          Some time ago we stumbled across the TEDx event from 2014{" "}
          <a
            href="https://www.youtube.com/watch?v=97ufCT6lQcY"
            target="_blank"
            className="cardanoLink"
          >
            The future will be decentralized
          </a>{" "}
          by Charles Hoskinson. This lecture had a strong impact on us. Since we
          already have a very sustainable philosophy of life and have been
          involved with cryptocurrencies for a while, we didn't need much
          convincing: "C'mon, let's run a Cardano Staking Pool!"
        </div>
      </div>
    </div>
  );
}

export default LandingPage;

interface ILogo {
  size: "Large" | "Small";
}

export const Logo: React.FunctionComponent<ILogo> = (props) => {
  return (
    <div className={`mainLogo${props.size}`}>
      <div className="logoWrapper">
        <div className="segment a"></div>
        <div className="segment b"></div>
        <div className="segment c"></div>
      </div>
    </div>
  );
};

const toFullInt = (num: string | number) => {
  if (typeof num === "string") {
    return Math.round(parseInt(num) / lovelance);
  } else {
    return Math.round(num / lovelance);
  }
};

function numberWithSpace(x: number) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}

interface IPoolDataProps {
  poolData: TPoolData;
  roa30d: number;
}

const PoolData: React.FunctionComponent<IPoolDataProps> = (props) => {
  const { poolData, roa30d } = props;
  return (
    <div className="poolData">
      <div>Blocks Minted: {poolData.blocks_minted} ₳ </div>
      <div>
        ROA over the last 50 days: {(Math.round(roa30d * 100) / 100).toFixed(2)}{" "}
        %{" "}
      </div>
      <div>
        Live Stake: {numberWithSpace(toFullInt(poolData.live_stake))} ₳{" "}
      </div>
      <div>
        Pledge: {numberWithSpace(toFullInt(poolData.declared_pledge))} ₳{" "}
      </div>
    </div>
  );
};
